:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --bike-blue: #1269A9;
  --bike-orange: #F7B247;
  --bike-white: #FFFAF2;


  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #ffffff;
}

body {
  color: rgb(var(--foreground-rgb));
  /* background-color: #000000; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

a {
  color: inherit;
  text-decoration: none;
}


/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

.page_header__kVzhN {
  width: 100%;

}

.page_main__GlU4n {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  min-height: 90vh;
  width: 100%;
  margin-left: 25px;
  margin-right: 25px;
}

.page_splash__KQ_Xr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  min-height: 90vh;
  width: 100%;
}

.page_landingBackground__TlCGG {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(#1985d2, #093459);
}

.page_landing__vDqfd {
  width: 100%;
  min-height: 100vh;
}

.page_loggedOut__xaT6W {
  width: 100%;
  min-height: 100vh;
}

.page_loggedIn__iyla2 {
  width: 100%;
  min-height: 100vh;

}

.page_glass__dc3G5 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: .25rem 0rem;
  padding: 0.5rem 0rem;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(14, 70, 113, 0.2);
}

.page_promoImage__3xsnF {
  position: relative;
  margin: .25rem;
  height: 59.3px;
  width: 200px;
}


@media only screen and (max-width: 600px) {
  .page_promoImage__3xsnF {
    height: 29.65px;
    width: 100px;
  }
}

.page_logoImage__eK2JD {
  position: relative;
  height: 50px;
  width: 50px;
}
.page_logoImageHelp__bzAn3 {
  position: relative;
  height: 5rem;
  width: 5rem;
  margin-right: 1rem;
  margin-left: 2rem;
}

.page_promoText__dLpYW {
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;
}

.page_authMain__pmTsQ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 90vh;
  width: 100%;
  /* background-color: aqua; */
}

.page_nav__zpG_3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bike-orange);
  width: 100%;
  text-align: center;
  height: 5rem;
}

.page_logo__7fc9l {
  margin-left: 11px;
  margin-right: 11px;
}

.page_navLogo__E6xem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: .5rem;
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.page_navLogout__HJBh8 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 1px; */
  /* padding: .5rem; */
  height: 100%;
  width: 50%;
}

.page_navItem__K5ZCd {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 5px; */
  padding: .5rem;
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.page_navItem__K5ZCd:hover {
  background-color: #fbc067;

}

.page_currentPath__aUNey {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  /* margin: 5px; */
  background-color: var(--bike-blue);
  color: var(--bike-white);
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.page_currentPath__aUNey:hover {
  background-color: #4083b3;
}



.page_bikeTableContainer__bJAFU {
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
  border: 1px solid lightgrey;
  scrollbar-color: var(--bike-blue) transparent;
  scrollbar-width: thin;

}

.page_content__kDoxQ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 25px;
  /* background-color: aqua; */
  width: 100%;
  height: 100%;
}

.page_flexCol__rW5NC {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.page_columnWrapper__P08iG {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* background-color: lime; */
  padding: .5rem;

}


.page_leftColumn__Hcggt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 75%;
  /* background-color: orange; */
  padding: .5rem;


}

.page_rightColumn__j8an9 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  /* background-color: blueviolet; */
  padding: .5rem;


}

.page_column__8vhW8 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  padding: .25rem;
  width: 99%;
}



.page_customTextField__LzP4t textarea::placeholder {
  font-weight: 500;
  opacity: .5;

}

.page_dialogContainer__zuipF {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 400px;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  overflow-y: scroll;
  scrollbar-color: var(--bike-blue) transparent;
  scrollbar-width: thin;
}

.page_dialogScroll__4XbGj {
  min-height: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.page_dialogContainerRight__0NxRJ {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  min-height: 400px;
  height: 100%;
  width: 100%;
  padding: 1.5rem;

}

.page_dialogContainerSmall__i_f_M {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  min-height: 400px;
  height: 100%;
  width: 100%;
  padding: 1.5rem;

}

.page_dialogContent__5YtNo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 100%;
  margin-bottom: 1rem;

}

.page_dialogContentLeft__s39DA {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  margin-bottom: 1rem;
}

.page_dialogTopRow__fRomd {
  width: 100%;
  /* height:100%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;


}

.page_dialogButtons__8CYJK {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  margin-top: 2rem;
}

.page_dashboardTopRow__4Elwu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.page_dialogSplitCol__0Foxp {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

}

.page_contentSplitCol__Gttgq {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;

}

.page_contentLeft__HX8F4 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  margin-bottom: 1rem;
  padding: 1.5rem;
}

.page_splitContentLeft__0dh4L {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  margin-bottom: .25rem;
  padding: .5rem;
}

.page_scrollSideways__d4MDs {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: scroll;
  min-width: 950px;
  width: 100%;
  min-height: 220px;
  height: 100%;
  padding: 1rem .5rem;
  scrollbar-color: var(--bike-blue) transparent;
}

.page_innerScroll__aUpfp {
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow-x: hidden;
  border: 1px solid lime;
  width: 100%;
  /* min-height:300px; */
  height: 100%;

}

.page_cardWrapper__g8x9g {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: .2rem;

}

.page_stickyColumn__wwlR5 {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: var(--bike-white);
  z-index: 9999 !important;
}

.page_mb__kiG0c {
  margin-bottom: 1rem;
}

.page_halfPad__AD4or {
  padding: .5rem;
}

.page_fileInput__GPmDd:hover {
  cursor: pointer;
}

.page_mobileNavWrapper__r2wDE {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 100;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.page_mobileNavMenu__cmEee {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 100;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;


}

.page_hamburgerMenu__w5ZRC {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.page_hamburgerLine__s6yqT {
  width: 100%;
  height: 2px;
  border: 1px solid yellow;


}

.page_dropdownMenu__qkuLY {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 100;
  background-color: var(--bike-white);
  border-left: 2px solid var(--bike-blue);
  border-right: 2px solid var(--bike-blue);
  border-bottom: 2px solid var(--bike-blue);
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;



}

.page_mobileNavItem__MIYEu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: small;
}



.page_mobileCurrentPath__0X9wW {
  display: flex;
  justify-content: center;
  text-align: flex-end;
  font-size: small;
  padding: 1rem;
  /* margin: 5px; */
  background-color: var(--bike-blue);
  color: var(--bike-white);
  height: 100%;
  width: 100%;
}

.page_mobileCurrentPath__0X9wW:focus {
  background-color: #4083b3;
}

.page_resourceRow__ETGXs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}

.page_dataBtn__2i3FY {
  border: 2px solid var(--bike-blue);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 6px 4px grey;

}

.page_dataBtn__2i3FY:hover {
  cursor: pointer;
  border: 2px solid var(--bike-orange);
  scale: 1.05;
  box-shadow: 5px 2px grey;
}

.page_dataBtn__2i3FY:active {
  cursor: pointer;
  scale: 1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

